

























































































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import ImportStudents from "@/components/import-students/index.vue";
import ImportExamPaper from "@/components/import-exam/index.vue";
import {
  ExamProjectPro,
  ExamSchool,
  ExamPaper,
  StudentExamPaperRule,
} from "@/tool/_class";
import { queryExamProjectProfile, setExamProject } from "@/api/examProject";
import { deleteProjectExamStudent } from "@/api/examStudent";
import { deleteExamPaper } from "@/api/examPaper";
import { formatTime } from "@/utils/date";
import * as _ from "lodash";
@Component({
  name: "ExamProjectEditor",
  components: {
    cardBox,
    ImportStudents,
    ImportExamPaper,
  },
})
export default class extends Vue {
  private showDialogImport: boolean = false;
  private showDialogImportType: string = "";
  private dialogTitle: string = "";
  private get title(): string {
    switch (this.stepIndex) {
      case 0:
        return "项目";
      case 1:
        return `${this.formExamProject.examProjectName}${
          this.formExamProject.examProjectName ? " - " : ""
        }考生`;
      case 2:
        return `${this.formExamProject.examProjectName}${
          this.formExamProject.examProjectName ? " - " : ""
        }试卷`;
    }
    return "";
  }
  private formExamProject: ExamProjectPro = new ExamProjectPro();
  private currentExamPaper: ExamPaper = new ExamPaper();
  private pageType: string = "";

  private dateEnd: any = {};
  private dateBegin: any = {};

  private studentExamPaperRules: {
    rule: StudentExamPaperRule;
    displayText: string;
  }[] = [
    { rule: StudentExamPaperRule.unknow, displayText: "无" },
    {
      rule: StudentExamPaperRule.examStudentSeatNoFirst,
      displayText: "编排座位号优先",
    },
    {
      rule: StudentExamPaperRule.localSeatNoFirst,
      displayText: "考场机位号优先",
    },
    { rule: StudentExamPaperRule.examAccountFirst, displayText: "考号优先" },
    { rule: StudentExamPaperRule.localIpFirst, displayText: "考场机位IP优先" },
    /*
    { rule: StudentExamPaperRule.seatNo, displayText: "考场机位号" },
    { rule: StudentExamPaperRule.ip, displayText: "考场机位IP" },
    { rule: StudentExamPaperRule.examStudentSeatNo, displayText: "编排座位号" },
    { rule: StudentExamPaperRule.examAccount, displayText: "考号" },
     */
  ];

  private examSchoolTagLabel(item: ExamSchool) {
    let examRooms = this.formExamProject.examRooms.filter((examRoom) => {
      return examRoom.schoolId == item.schoolId;
    });
    let schoolTypes: string[] = [];
    if (item.primarySchool) {
      schoolTypes.push("小");
    }
    if (item.middleSchool) {
      schoolTypes.push("中");
    }
    if (item.highSchool) {
      schoolTypes.push("高");
    }
    if (examRooms.length > 0) {
      return `${item.schoolName}[${schoolTypes.toString()}] (${examRooms
        .map((item) => {
          return item.examRoomName;
        })
        .toString()}) / ${item.standardExamStudents}人`;
    }
    return `${item.schoolName}[${schoolTypes.toString()}] / ${
      item.standardExamStudents
    }人`;
  }

  private stepIndex: number = 0;

  private doPrevStepClick() {
    if (this.stepIndex > 0) {
      this.stepIndex--;
    }
  }
  private async doNextStepClick() {
    try {
      if (this.stepIndex == 0) {
        const resp: any = await this.saveExamProject();
        if (resp.code !== 0) {
          throw new Error(resp.msg);
        }
        /*
        this.$message({
          type: "success",
          message: "保存成功"
        });
      */
      }
      if (this.stepIndex < 2) {
        this.stepIndex++;
      }
    } catch (error: any) {
      this.$message({
        type: "warning",
        message: error.message || error.msg || "ERROR",
      });
    }
  }

  private doGoBackClick() {
    this.$router.go(-1);
  }

  private examProjectTimesValidator(rule: any, value: any, callback: Function) {
    if (
      this.formExamProject.examStartTime &&
      this.formExamProject.examEndTime &&
      this.formExamProject.examStartTime <= this.formExamProject.examEndTime
    ) {
      callback();
    } else {
      callback(new Error("开始、结束日期不能为空，并且日期选择要合理"));
    }
  }

  private step0Rules: any = {
    examProjectName: [
      {
        required: true,
        message: "请输入考试项目名称",
        trigger: "blur",
      },
    ],
    examProjectTimes: {
      validator: this.examProjectTimesValidator,
      trigger: "blur",
    },
  };

  private async saveExamProject() {
    let __valid: boolean = false;
    (this.$refs["formExamProjectStep0"] as any).validate((valid: boolean) => {
      __valid = valid;
      if (valid) {
        //
      } else {
        return false;
      }
    });
    if (!__valid) {
      throw new Error("表单信息校验未通过。");
    }
    this.formExamProject.examStartTime = `${formatTime(
      this.formExamProject.examStartTime,
      "yyyy-MM-dd"
    ).substring(0, 10)} 00:00:00`;
    this.formExamProject.examEndTime = `${formatTime(
      this.formExamProject.examEndTime,
      "yyyy-MM-dd"
    ).substring(0, 10)} 00:00:00`;
    return await setExamProject(this.formExamProject);
  }

  private beginDataChange(times: { getTime: () => number }) {
    if (times) {
      this.dateEnd = {
        disabledDate(time: { getTime: () => number }) {
          return time.getTime() < times.getTime();
        },
      };
    }
  }
  private endDataChange(times: { getTime: () => number }) {
    if (times) {
      this.dateBegin = {
        disabledDate(time: { getTime: () => number }) {
          return time.getTime() > times.getTime();
        },
      };
    }
  }

  private closeExamSchool(data: ExamSchool) {
    this.$confirm(
      `此操作将移除“${data.schoolName}”该考点所有考生, 是否继续?`,
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    )
      .then(() => {
        deleteProjectExamStudent({
          examProjectId: this.formExamProject.examProjectId,
          examSchoolId: data.schoolId,
        }).then((res: any) => {
          if (res.code == 0) {
            this.formExamProject.examSchools.splice(
              this.formExamProject.examSchools.indexOf(data),
              1
            );
          }
        });
      })
      .catch(() => {});
  }
  private doExamPaperClick(examPaper: ExamPaper) {
    this.currentExamPaper = examPaper;
    this.showDialogImport = true;
    this.showDialogImportType = "examPaper";
    this.dialogTitle = "修改试卷";
  }
  private closeExamPaper(data: ExamPaper) {
    this.$confirm("此操作将移除该试卷, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteExamPaper(data.examPaperId).then((res: any) => {
          if (res.code == 0) {
            this.formExamProject.examPapers.splice(
              this.formExamProject.examPapers.indexOf(data),
              1
            );
            this.currentExamPaper = new ExamPaper();
          }
        });
      })
      .catch(() => {});
  }

  private doImportExamPaperClick() {
    if (
      !this.formExamProject.examStartTime ||
      !this.formExamProject.examEndTime
    ) {
      return this.$message({
        message: "请选择考试时间",
        type: "warning",
      });
    }
    this.formExamProject.examStartTime = `${formatTime(
      this.formExamProject.examStartTime,
      "yyyy-MM-dd"
    ).substring(0, 10)} 00:00:00`;
    this.formExamProject.examEndTime = `${formatTime(
      this.formExamProject.examEndTime,
      "yyyy-MM-dd"
    ).substring(0, 10)} 00:00:00`;
    //
    this.currentExamPaper = new ExamPaper();
    //
    this.showDialogImport = true;
    this.showDialogImportType = "examPaper";
    this.dialogTitle = "导入试卷";
  }
  private closeShowDialog() {
    this.showDialogImport = false;
    switch (this.showDialogImportType) {
      case "examPaper": {
        this.formExamProject.examPapers.splice(0, 0);
        break;
      }
      case "students": {
        this.formExamProject.examSchools.splice(0, 0);
        break;
      }
    }
    
    this.showDialogImportType = "";
    
    //alert(JSON.stringify(this.formExamProject.examPapers))
  }
  mounted() {
    if (this.$route.query.examProjectId) {
      queryExamProjectProfile(this.$route.query.examProjectId as string).then(
        (res) => {
          this.formExamProject = _.merge(new ExamProjectPro(), res.data);
          for (let i = 0; i < this.formExamProject.examPapers.length; i++) {
            this.formExamProject.examPapers[i] = _.merge(
              new ExamPaper(),
              this.formExamProject.examPapers[i]
            );
          }
          console.log(`${JSON.stringify(this.formExamProject)}`);
        }
      );
    }
    this.pageType = (this.$route.query.type as string) || "add";
  }
}
